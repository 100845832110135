<template>
  <div class="highlight">
    <span>
      <slot />
    </span>
  </div>
</template>

<script>
import "./highlight.scss";

export default {
  name: "Highlight",
};
</script>
